.SharingMenu {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
  padding: 8px 8px 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color ease-out 150ms; }
  .SharingMenu.SharingMenu__visible {
    background-color: rgba(0, 0, 0, 0.4); }
    .SharingMenu.SharingMenu__visible .SharingMenu__actions {
      transform: translateY(-8px);
      transform: translateY(calc(-8px - constant(safe-area-inset-bottom)));
      transform: translateY(calc(-8px - env(safe-area-inset-bottom))); }
  .SharingMenu.SharingMenu__story_accept:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    content: '';
    background-image: linear-gradient(180deg, rgba(37, 39, 74, 0) 0%, #25274A 76%); }
  .SharingMenu.SharingMenu__story_accept:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, 0.4); }
  .SharingMenu.SharingMenu__story_accept .SharingMenu__actions {
    transform: translateY(100%); }
  .SharingMenu.SharingMenu__story_accept .StorySharing {
    z-index: 2;
    transform: translateY(-8px);
    transform: translateY(calc(-8px - constant(safe-area-inset-bottom)));
    transform: translateY(calc(-8px - env(safe-area-inset-bottom))); }
  .SharingMenu__actions {
    width: 100%;
    transform: translateY(100%);
    transition: transform cubic-bezier(0.36, 0.66, 0.04, 1) 300ms; }
  .SharingMenu__list {
    overflow: hidden;
    border-radius: 14px;
    background-color: #FFFFFF; }
  .SharingMenu__item {
    font-size: 20px;
    line-height: 24px;
    padding: 16px 10px;
    text-align: center;
    color: #2787F5; }
    .SharingMenu__item:not(:last-of-type) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .SharingMenu__cancel {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 8px;
    padding: 16px 10px;
    text-align: center;
    color: #2787F5;
    border-radius: 14px;
    background-color: #FFFFFF; }

.Tanos_desktop .SharingMenuDesktop {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  padding-bottom: 4px; }
  .Tanos_desktop .SharingMenuDesktop .SharingMenu__actions {
    z-index: 2;
    position: relative;
    transform: none; }

.Tanos_desktop .SharingMenu.SharingMenu__visible {
  background: none; }

.Tanos_desktop .SharingMenu.SharingMenu__story_accept:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 279px;
  content: '';
  background-image: linear-gradient(180deg, rgba(37, 39, 74, 0) 0%, #25274A 76%); }

.Tanos_desktop .SharingMenu.SharingMenu__story_accept:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: rgba(0, 0, 0, 0.6); }

.Tanos_desktop .SharingMenu.SharingMenu__story_accept .StorySharing {
  z-index: 2;
  transform: translateY(-40px); }

.Tanos_desktop .SharingMenu__actions.SharingMenu__actions_hidden {
  display: none; }

.Tanos_desktop .SharingMenu__list {
  padding: 4px 0; }

.Tanos_desktop .SharingMenu__item {
  font-size: 13px;
  line-height: 19px;
  padding: 7px 12px;
  text-align: left;
  border: none;
  color: #000000; }
  .Tanos_desktop .SharingMenu__item:hover {
    background-color: #E7EDF1; }

.Tanos_android .SharingMenu__list {
  border-radius: 12px; }

.Tanos_android .SharingMenu__item {
  font-size: 16px;
  line-height: 20px;
  padding: 12px 20px;
  text-align: left;
  color: #000000;
  border: none; }
