.FinalScreen {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%; }
  .FinalScreen:before {
    position: fixed;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url("../CounterScreen/img/bg_mobile.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    filter: blur(20px); }
  .FinalScreen__inner {
    font-size: 0;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 16px;
    background-image: linear-gradient(0deg, rgba(23, 25, 58, 0.6) 50%, rgba(111, 32, 62, 0.6) 73%); }
  .FinalScreen__part {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 50%;
    text-align: center; }
  .FinalScreen__part_inner {
    width: 100%;
    margin-top: -20px; }
  .FinalScreen__disappeared {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top); }
  .FinalScreen__middle {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%); }
    .FinalScreen__middle:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: inline-block;
      width: calc(100vw - 32px);
      height: 1px;
      transform: translateX(-50%);
      content: '';
      background-color: rgba(255, 255, 255, 0.1); }
    .FinalScreen__middle.FinalScreen__middle_translation {
      z-index: 1;
      bottom: -18px; }
      .FinalScreen__middle.FinalScreen__middle_translation:before, .FinalScreen__middle.FinalScreen__middle_translation:after {
        position: absolute;
        bottom: 50%;
        left: auto;
        display: inline-block;
        width: calc((100vw - 257px)/2);
        height: 1px;
        transform: translateY(-50%);
        content: '';
        background-color: rgba(255, 255, 255, 0.1); }
      .FinalScreen__middle.FinalScreen__middle_translation:before {
        left: 0; }
      .FinalScreen__middle.FinalScreen__middle_translation:after {
        right: 0; }
  .FinalScreen__count {
    font-family: TT Commons;
    font-size: 70px;
    font-weight: 800;
    line-height: 76px; }
  .FinalScreen__text {
    font-family: TT Commons;
    font-size: 19px;
    font-weight: 400;
    line-height: 22px; }
  .FinalScreen__title {
    font-family: TT Commons;
    font-size: 35px;
    font-weight: 800;
    line-height: 36px;
    margin: 0 auto 20px;
    color: #D9A9FF;
    text-shadow: 2px 0 30px rgba(0, 0, 0, 0.3); }
  .FinalScreen__disappeared .FinalScreen__count {
    color: #F2E1FF;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #F2E1FF; }
  .FinalScreen__disappeared .FinalScreen__text {
    color: #F5E7FF; }
  .FinalScreen__survivors .FinalScreen__count {
    color: #D9A9FF; }
  .FinalScreen__survivors .FinalScreen__text {
    color: #D0A7F1; }
  .FinalScreen__survivors .FinalScreen__part_inner {
    padding-top: 39px; }
  .FinalScreen__button_wrap {
    position: relative;
    display: inline-block; }
    .FinalScreen__button_wrap .FinalScreen__button {
      width: 160px; }
    .FinalScreen__button_wrap .SharingMenuDesktop {
      top: 100%;
      bottom: auto;
      padding-bottom: 0;
      padding-top: 4px; }

.Tanos_desktop .FinalScreen__disappeared:after {
  width: 400px; }

.Tanos_desktop .FinalScreen__survivors .FinalScreen__part_inner {
  padding-top: 45px; }

.Tanos_desktop .FinalScreen__title {
  font-size: 32px;
  line-height: 33px;
  max-width: 350px;
  margin-top: 0; }

.Tanos_desktop .FinalScreen__text {
  font-size: 17px; }
