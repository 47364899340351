.StartScreen {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  transition: filter ease-in 100ms; }
  .StartScreen.StartScreen__terms_opened {
    filter: blur(15px); }
  .StartScreen__copyright {
    font-size: 11px;
    font-weight: 600;
    line-height: 12px;
    z-index: 1;
    position: absolute;
    top: 36px;
    left: 12px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7); }
    @supports (top: constant(safe-area-inset-top)) {
      .StartScreen__copyright {
        top: calc(12px + constant(safe-area-inset-top)); } }
    @supports (top: env(safe-area-inset-top)) {
      .StartScreen__copyright {
        top: calc(12px + env(safe-area-inset-top)); } }
  .StartScreen__inner {
    position: relative;
    background-image: url("./img/bg_mobile.jpg");
    background-size: 100%;
    background-position: top left;
    background-repeat: no-repeat; }
    .StartScreen__inner:before {
      position: absolute;
      top: 12.27vw;
      left: 0;
      display: inline-block;
      width: 100vw;
      height: 66vw;
      content: '';
      background-image: linear-gradient(180deg, rgba(37, 39, 74, 0) 0%, #25274A 84%); }
  .StartScreen__content {
    position: relative;
    width: 100%;
    min-height: 461px;
    padding: 59.73vw 16px 20px; }
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
      .StartScreen__content {
        padding-bottom: calc(20px + constant(safe-area-inset-bottom)); } }
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      .StartScreen__content {
        padding-bottom: calc(20px + env(safe-area-inset-bottom)); } }
    @media (max-height: 560px) and (max-width: 370px) {
      .StartScreen__content {
        padding-top: 40vw; } }
    @media (max-height: 640px) {
      .StartScreen__content.StartScreen__content_first {
        padding-top: 130px; } }
    @media (max-height: 560px) and (max-width: 370px) {
      .StartScreen__content.StartScreen__content_first {
        padding-bottom: 70px; }
        .StartScreen__content.StartScreen__content_first .StartScreen__actions {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 20px;
          background-color: #25274A; } }
  .StartScreen__title {
    font-family: TT Commons;
    font-size: 44px;
    font-weight: 800;
    line-height: 46px;
    max-width: 280px;
    margin: 0 auto 10px;
    padding-right: 72px;
    word-wrap: initial;
    text-align: center;
    text-transform: uppercase;
    color: #D9A9FF; }
  .StartScreen__info {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 24px;
    text-align: center;
    color: #D0A7F1; }
  .StartScreen__text:not(:last-of-type) {
    margin-bottom: 19px; }
  .StartScreen__actions {
    padding: 0 4px;
    text-align: center; }

.Tanos_desktop .StartScreen__copyright {
  font-size: 10px;
  top: 18px;
  left: 20px; }

.Tanos_desktop .StartScreen__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  background-image: url("./img/bg.jpg"); }
  .Tanos_desktop .StartScreen__inner:before {
    top: calc(100vw * .0545);
    height: calc(100vw * .445); }

.Tanos_desktop .StartScreen__content {
  min-height: unset;
  padding: 40px; }

.Tanos_desktop .StartScreen__title {
  font-size: 39px;
  max-width: 100%;
  padding: 0;
  margin-bottom: 11px; }

.Tanos_desktop .StartScreen__info {
  line-height: 20px;
  margin-bottom: 26px; }

.Tanos_desktop .StartScreen__text:not(:last-of-type) {
  margin-bottom: 20px; }

.Tanos_android .StartScreen__copyright {
  top: 22px; }
