.CounterScreen {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  transition: filter ease 100ms; }
  .CounterScreen__copyright {
    font-size: 11px;
    font-weight: 600;
    line-height: 12px;
    z-index: 1;
    position: absolute;
    top: 36px;
    left: 12px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7); }
    @supports (top: constant(safe-area-inset-top)) {
      .CounterScreen__copyright {
        top: calc(12px + constant(safe-area-inset-top)); } }
    @supports (top: env(safe-area-inset-top)) {
      .CounterScreen__copyright {
        top: calc(12px + env(safe-area-inset-top)); } }
  .CounterScreen__inner {
    position: relative;
    min-height: 461px;
    padding: 117.87vw 16px 20px;
    background-image: url("./img/bg_mobile.jpg");
    background-size: 100%;
    background-position: top left;
    background-repeat: no-repeat; }
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
      .CounterScreen__inner {
        padding-bottom: calc(20px + constant(safe-area-inset-bottom)); } }
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      .CounterScreen__inner {
        padding-bottom: calc(20px + env(safe-area-inset-bottom)); } }
    @media (max-height: 660px) {
      .CounterScreen__inner {
        padding-top: 77.87vw; } }
    @media (max-height: 560px) {
      .CounterScreen__inner {
        padding-top: 70vw; } }
    .CounterScreen__inner:before {
      position: absolute;
      top: 47.2vw;
      left: 0;
      display: inline-block;
      width: 100vw;
      height: 100vw;
      content: '';
      background-image: linear-gradient(180deg, rgba(37, 39, 74, 0) 0%, #25274A 84%); }
  .CounterScreen__content {
    position: relative;
    text-align: center; }
  .CounterScreen__title {
    font-family: TT Commons;
    font-weight: 800;
    font-size: 32px;
    line-height: 34px;
    max-width: 300px;
    margin: 0 auto 6px;
    text-transform: uppercase;
    color: #D9A9FF; }
  .CounterScreen__info {
    font-family: TT Commons;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #D0A7F1; }
  .CounterScreen__actions {
    margin-top: 24px; }
  .CounterScreen__button_wrap {
    margin-bottom: 8px; }
  .CounterScreen .CounterScreen__button {
    display: block;
    width: 197px;
    margin: 0 auto; }
    .CounterScreen .CounterScreen__button:not(:last-of-type) {
      margin-bottom: 8px; }

.Tanos_desktop .CounterScreen__copyright {
  font-size: 10px;
  top: 18px;
  left: 20px; }

.Tanos_desktop .CounterScreen__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  padding: 40px;
  background-image: url("../StartScreen/img/bg.jpg"); }
  .Tanos_desktop .CounterScreen__inner:before {
    top: 5.45vw;
    height: 44.5vw; }

.Tanos_desktop .CounterScreen__content {
  min-width: 500px; }

.Tanos_desktop .CounterScreen__info {
  font-size: 16px; }

.Tanos_desktop .CounterScreen__actions {
  margin-top: 40px; }

.Tanos_desktop .CounterScreen__button_wrap {
  position: relative;
  display: inline-block;
  margin-right: 8px; }

.Tanos_desktop .CounterScreen .CounterScreen__button {
  display: inline-block; }
  .Tanos_desktop .CounterScreen .CounterScreen__button:not(:last-of-type) {
    margin: 0;
    margin-right: 8px; }

.Tanos_android .CounterScreen__copyright {
  top: 22px; }
