.StorySharing {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 36px;
  transform: translateY(100%);
  transition: transform cubic-bezier(0.36, 0.66, 0.04, 1) 300ms; }
  .StorySharing__wrap {
    z-index: 1;
    position: fixed;
    left: 0;
    width: 100%; }
  .StorySharing__inner {
    width: 76vw;
    max-width: calc((100vh - 140px) * .64);
    margin: 0 auto; }
  .StorySharing__story {
    width: 100%;
    height: calc(76vw * 1.7874);
    max-height: calc(100vh - 140px);
    margin-bottom: 16px;
    border-radius: 14px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  .StorySharing .StorySharing__button:not(:last-of-type) {
    margin-bottom: 10px; }
  .StorySharing__success {
    width: 100%;
    padding: 0 8px;
    animation: showSuccess 2000ms linear 0s 1 forwards; }
  .StorySharing__success_inner {
    padding: 12px;
    text-align: center;
    border-radius: 10px;
    background-color: #FFFFFF; }
  .StorySharing__success_text {
    font-size: 15px;
    line-height: 32px;
    vertical-align: top;
    color: #000000; }
    .StorySharing__success_text:before {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: 8px;
      content: '';
      vertical-align: top;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='32' height='32' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M12.219 3.429h6.42c3.056 0 4.164.318 5.282.915a6.23 6.23 0 0 1 2.592 2.593c.597 1.117.916 2.225.916 5.282v6.42c0 3.056-.319 4.164-.916 5.282a6.23 6.23 0 0 1-2.592 2.592c-1.118.597-2.226.916-5.283.916H12.22c-3.057 0-4.165-.319-5.282-.916a6.23 6.23 0 0 1-2.593-2.592c-.597-1.118-.915-2.226-.915-5.283V12.22c0-3.057.318-4.165.915-5.282a6.23 6.23 0 0 1 2.593-2.593c1.117-.597 2.225-.915 5.282-.915zm-.58 2.057c-2.14 0-2.915.222-3.698.64a4.362 4.362 0 0 0-1.814 1.815c-.419.783-.641 1.558-.641 3.698v7.58c0 2.139.222 2.915.64 3.697a4.362 4.362 0 0 0 1.815 1.814c.783.419 1.558.641 3.698.641h7.58c2.139 0 2.915-.222 3.697-.64a4.362 4.362 0 0 0 1.814-1.815c.419-.782.641-1.558.641-3.698V11.64c0-2.14-.222-2.915-.64-3.698a4.362 4.362 0 0 0-1.815-1.814c-.782-.419-1.558-.641-3.698-.641H11.64zm-1.925 9.943a5.716 5.716 0 0 1 5.715-5.715 5.716 5.716 0 0 1 5.714 5.715 5.716 5.716 0 0 1-5.714 5.714 5.716 5.716 0 0 1-5.715-5.714zm2.057 0a3.657 3.657 0 1 0 7.314 0 3.657 3.657 0 0 0-7.314 0z' id='a'/%3E%3C/defs%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h32v32H0z'/%3E%3Cuse fill='%23528BCC' fill-rule='nonzero' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: cover; }

.Tanos_desktop .StorySharing__inner {
  width: 258px; }

.Tanos_desktop .StorySharing__story {
  width: 258px;
  height: 461px; }

.Tanos_desktop .StorySharing .StorySharing__button:not(:last-of-type) {
  width: 136px;
  margin: 0;
  margin-right: 10px; }

@keyframes showSuccess {
  0% {
    transform: translateY(100%); }
  10% {
    transform: translateY(-8px);
    transform: translateY(calc(-8px - constant(safe-area-inset-bottom)));
    transform: translateY(calc(-8px - env(safe-area-inset-bottom))); }
  90% {
    transform: translateY(-8px);
    transform: translateY(calc(-8px - constant(safe-area-inset-bottom)));
    transform: translateY(calc(-8px - env(safe-area-inset-bottom))); }
  100% {
    transform: translateY(100%); } }
