.TermsScreen {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba(37, 39, 74, 0.55);
  transition: opacity ease 50ms; }
  .TermsScreen.TermsScreen_visible {
    opacity: 1; }
  .TermsScreen__inner {
    min-height: 290px;
    padding: 56px 16px 20px; }
  .TermsScreen__title {
    font-family: TT Commons;
    font-size: 26px;
    font-weight: 800;
    line-height: 44px;
    margin-bottom: 2px;
    text-align: center;
    text-transform: uppercase;
    color: #D9A9FF; }
  .TermsScreen__info {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 23px;
    text-align: center;
    color: #D0A7F1; }
  .TermsScreen__text:not(:last-of-type) {
    margin-bottom: 22px; }
  .TermsScreen .TermsScreen__button {
    display: block;
    width: 197px;
    margin: 0 auto; }
    .TermsScreen .TermsScreen__button:not(:last-of-type) {
      margin-bottom: 9px; }

.Tanos_desktop .TermsScreen__inner {
  max-width: 440px;
  padding-top: 45px; }

.Tanos_desktop .TermsScreen__title {
  font-size: 24px; }

.Tanos_desktop .TermsScreen__info {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 21px; }

.Tanos_desktop .TermsScreen__text:not(:last-of-type) {
  margin-bottom: 21px; }

.Tanos_desktop .TermsScreen__actions {
  text-align: center; }

.Tanos_desktop .TermsScreen .TermsScreen__button {
  display: inline-block; }
  .Tanos_desktop .TermsScreen .TermsScreen__button:not(:last-of-type) {
    margin: 0;
    margin-right: 8px; }
