.BottomPopup {
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000;
  border-radius: 14px 14px 0 0;
  background-color: transparent;
  overflow-y: auto;
  width: 100%;
  max-height: calc(100vh - 100px);
  box-sizing: border-box; }
  .BottomPopup__header {
    border-radius: 14px 14px 0 0;
    background: #EBEFF4;
    font-size: 17px;
    color: #222222;
    font-weight: 500; }
  .BottomPopup__buttons {
    display: block; }
  .BottomPopup__footer {
    padding: 20px 16px 20px 16px; }
    .BottomPopup__footer > .Button {
      width: 100%;
      box-sizing: border-box; }
  .BottomPopup__close {
    position: absolute;
    z-index: 1;
    top: 14px;
    right: 14px;
    background: url("./img/close.svg") no-repeat center center/cover;
    width: 24px;
    height: 24px; }

.PopupAnimation-enter {
  background-color: rgba(0, 0, 0, 0) !important; }
  .PopupAnimation-enter.BottomPopup {
    transform: translateY(100%); }

.PopupAnimation-enter.PopupAnimation-enter-active {
  background-color: rgba(0, 0, 0, 0.4) !important;
  transition: background-color 200ms ease-in; }
  .PopupAnimation-enter.PopupAnimation-enter-active.BottomPopup {
    transform: translateY(0%);
    transition: transform 200ms ease-out; }

.PopupAnimation-leave {
  background-color: rgba(0, 0, 0, 0.4); }
  .PopupAnimation-leave.BottomPopup {
    transform: translateY(0%); }

.PopupAnimation-leave.PopupAnimation-leave-active {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 200ms ease-in; }
  .PopupAnimation-leave.PopupAnimation-leave-active.BottomPopup {
    transform: translateY(100%);
    transition: transform 200ms ease-in; }

.BottomPopupShadow {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.PopupAnimationWrapper {
  width: 100%; }
