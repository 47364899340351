.Button {
  font-family: -apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  display: inline-block;
  height: 36px;
  margin: 0;
  padding: 7px 16px 8px;
  zoom: 1;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  vertical-align: top;
  text-align: center;
  text-decoration: none;
  color: #FFFFFF;
  border: 0;
  border-radius: 18px;
  box-sizing: border-box;
  background: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  transition: background-color ease-out 100ms; }
  .Button--large {
    font-size: 17px;
    line-height: 30px;
    width: 100%;
    height: 44px;
    border-radius: 10px; }
  .Button--color {
    background-color: #872DBA; }
    .Button--color.Button--active {
      background-color: #7e2aae; }
  .Button--primary {
    background-color: #4986cc;
    box-shadow: none; }
    .Button--primary.Button--active {
      background-color: #3d7ec9; }
  .Button--transparent {
    background-color: rgba(255, 255, 255, 0.06); }
    .Button--transparent.Button--active {
      background-color: rgba(255, 255, 255, 0.04); }
  .Button--link {
    width: 100%;
    height: auto;
    padding: 0;
    color: #2787F5;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none; }
    .Button--link.Button--active {
      background-color: #d7d8d9; }

.Tanos_desktop .Button {
  font-size: 14px;
  padding-right: 20px;
  padding-left: 20px; }
  .Tanos_desktop .Button:hover.Button--color {
    background-color: #9030c6; }
  .Tanos_desktop .Button:hover.Button--primary {
    background-color: #558ecf; }
  .Tanos_desktop .Button:hover.Button--transparent {
    background-color: rgba(255, 255, 255, 0.08); }
  .Tanos_desktop .Button:active.Button--color {
    background-color: #7e2aae; }
  .Tanos_desktop .Button:active.Button--primary {
    background-color: #3d7ec9; }
  .Tanos_desktop .Button:active.Button--transparent {
    background-color: rgba(255, 255, 255, 0.04); }
