.Error {
  height: 100vh;
  position: relative;
  background-color: #ebedf0; }
  .Error__body {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
    box-sizing: border-box; }
  .Error__sad {
    width: 72px;
    height: 72px;
    background: url("./img/error.png") no-repeat center center/cover;
    margin: 0 auto 20px; }
  .Error__title {
    font-size: 30px;
    font-weight: 700;
    color: #2C2D2E;
    text-align: center;
    line-height: 34px; }
  .Error__bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px;
    padding-bottom: calc(25px + constant(safe-area-inset-bottom));
    padding-bottom: calc(25px + env(safe-area-inset-bottom)); }
  .Error__bottom_button {
    width: 100%; }
  .Error__to-details {
    padding-bottom: 15px; }
  .Error__details {
    background: #ffffff;
    padding: 16px;
    padding-bottom: calc(16px + constant(safe-area-inset-bottom));
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
    border-radius: 14px 14px 0 0;
    box-sizing: border-box;
    min-height: 150px; }
  .Error__details-header {
    font-weight: 600;
    font-size: 17px;
    color: #222222;
    line-height: 20px;
    padding-bottom: 12px; }
  .Error__details-text {
    font-size: 15px;
    color: #000000;
    line-height: 20px; }
  .Error__close {
    text-align: center;
    width: 100%; }
    .Error__close a {
      color: var(--button_secondary_foreground);
      font-weight: 500; }
