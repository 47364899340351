.ScreenSpinner {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
  .ScreenSpinner__spinner {
    width: 44px;
    height: 44px;
    background-image: url("./img/spinner.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    animation: rotator .7s linear infinite;
    transform-origin: center; }

@keyframes rotator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
